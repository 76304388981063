import React from "react";
import TextPageTemplate, {
  TextBox,
  Paragraph,
  TinyTitle,
} from "../templates/textPageTemplate";
import Button from "../components/Button";

const Contact = () => {
  const handleButtonClick = () => {
    window.open("https://calendly.com/theodorhillmann/letswork", "_blank");
  };
  return (
    <TextPageTemplate
      title={"Imprint"}
      description={
        "Theodor Hillmann, an experienced interaction and interface designer, specializes in creating engaging and user-centered web experiences for forward-thinking companies. "
      }
    >
      <TextBox
        gridColumn={"2/4"}
        gridColumnDesktop={"2/3"}
        gridColumnMedium={"2/5"}
        gridRow={3}
      >
        <TinyTitle>Studio</TinyTitle>
        <Paragraph>
          Theodor Hillmann <br />
          c/o Studio Oben <br />
          Obentrautstr. 72 <br />
          10963, Berlin
        </Paragraph>
        <TinyTitle>Email</TinyTitle>
        <Paragraph>hello@theodorhillmann.de</Paragraph>
        <TinyTitle>Phone</TinyTitle>
        <Paragraph>0176 (0) 341 790 97</Paragraph>
        <Button
          marginTop={"1rem"}
          black
          isHidden={false}
          onClick={handleButtonClick}
        >
          {" "}
          Book a meet!
        </Button>
      </TextBox>

      <TextBox
        gridColumn={"4/6"}
        gridColumnDesktop={"3/4"}
        gridColumnMedium={"2/5"}
        gridRow={3}
        gridRowMedium={4}
      >
        <TinyTitle>Services</TinyTitle>
        <Paragraph>
          Product Design <br />
          Web Design <br />
          UX/UI Design <br />
          Brand Building <br />
          Digital Strategy <br />
          Creative Direction <br />
          Prototyping <br />
          Creative Code <br />
          User Research <br />
        </Paragraph>
      </TextBox>
    </TextPageTemplate>
  );
};

export default Contact;