import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { cursors, colors } from "../styles/variables";

const StyledButton = styled.button`
  ${(props) => props.isHidden && `opacity: 0;`}

  text-decoration: none;
  color: ${(props) => (props.black ? `${colors.white};` : colors.black)};
  cursor: ${cursors.bigCross};
  background: ${(props) => (props.black ? `${colors.black};` : "none;")};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: ${(props) => (props.black ? `500;` : `400;`)};
  align-self: flex-start;
  justify-self: flex-start;
  transition: transform 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  border-radius: ${(props) => (props.black ? `0.2rem;` : `1rem;`)};
  grid-column: 1;
  ${(props) => props.mobileMarginTop && `margin-top: ${props.mobileMarginTop};`}
  &:hover {
    border-radius: 2rem;
  }
  &:active {
    transform: scale(0.95);
  }
`;

const Button = ({
  children,
  to,
  onClick,
  isHidden,
  black,
  marginTop,
  mobileMarginTop,
}) => {
  const handleClick = () => {
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick();
    }
  };
  return (
    <StyledButton
      isHidden={isHidden}
      marginTop={marginTop}
      black={black}
      mobileMarginTop={mobileMarginTop}
      onClick={() => handleClick()}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
